<!-- 订单评价--结果 -->
<template>
  <div class="remarkinfo">
    <div class="title">
      <div class="top">
        <span><svg><use xlink:href="#iconwancheng"></use></svg></span>
        <h6>评价成功！以下商品未评，快去看看吧~</h6>
      </div>
      <p class="p">感谢您的评价，春风十里不如您的一句满意~<a href="javascript:;" @click='backlist'>返回待评价列表>></a></p>
    </div>
    <waitremark class='waitremark'></waitremark>
  </div>
</template>
<script>
import waitremark from '@/components/waitremark/waitremark.vue';//底部滚动展示
export default {
  name: 'remarkinfo',
  components: {
    waitremark
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'订单评价-微微定',
      sltms:false,
    }
  },
  props: {
    msg: String
  },
  methods:{
    backlist(){
      // 退会待评价列表
      // 评价管理
      var _obj = {
        orderTypes:1,
        pageIndexs:1
      }
      window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
      this._router({then:()=>{
        this.$router.push('/userremark.html')
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/userremark.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.remarkinfo {
  .title {
    width: 1200px;margin:0 auto;padding-top:55px;padding-bottom:35px;
    .top {
        display: flex;justify-content: flex-start;width: 517px;margin:0 auto;
        span {
          display: inline-block;width: 45px;height: 45px;background: #5fddb2;border-radius: 50%;display: flex;justify-content: center;align-items: center;margin-top:-3px;margin-right: 10px;
          svg {
            fill: #fff;width: 35px;height: 35px;
          }
        }
        h6 {
          font-size: 26px;
        }
    }
    .p {
      font-size: 18px;padding-left:398px;margin-top:5px;
      a {
        color:#3f88f4;font-size: 18px;margin-left:20px;
      }
    }
  }
  .waitremark {
    width: 1200px;margin:0 auto;margin-bottom:30px;
  }
}
</style>
