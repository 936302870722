<!-- 待评价列表 -->
<template>
  <div class="waitremark">
    <div  class="item" v-for='item,index in lists' :key='index'>
      <div class="img">
        <img :src="item.orderDeailJobList[0]?item.orderDeailJobList[0].ImageUrl:'' | imgsrc(that)">
      </div>
      <div class="info">
        <p class="price"><span>&yen;</span>{{item.orderDeailJobList[0]?item.orderDeailJobList[0].Price:''}}</p>
        <p :title='"微微定商城湿纸巾单价10元一包/质量好值得信赖"' class="h">{{item.orderDeailJobList[0]?item.orderDeailJobList[0].JobSubsTypesName:''}}</p>
      </div>
      <button class="remark" @click='rmkitem(item.orderDeailJobList[0],item.OrderName)'>去评价</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'waitremark',
  props: {
    msg: String
  },
  data(){
    return {
      lists:null,
      that:this,
    }
  },
  methods:{
    rmkitem(item,v){
        // console.log(this.$route.fullPath)
        this._router({then:()=>{
          this.$router.push({path: "/remarkinfo?ProductType="+item.ProductType+'&orderName='+v+'.html'})
          // this._open({path: "/remarkinfo?ProductType="+item.ProductType})
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:"/remarkinfo?ProductType="+item.ProductType+'&orderName='+v+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    },
  },
  mounted(){
    // 请求数据
    this._axios({then:()=>{
      this.axios.post(process.env.VUE_APP_URL+'UserCenter/EvaluateOrderList?status=1&pageIndex=1&pageSize=5',this.keys({token:this._token({method:'get'})}))
      .then(res => {
          if(res.data.Success){
            this.lists = res.data.Data.list.List
          }
        }, res => {
          console.log('系统异常')
        })
    },exp:true,catch:()=>{
      this.$router.push({path:'/accountlogin',query:{redirect:'/usercenterorder.html'}})
      // this._open({path: "/accountlogin",query: {redirect:'/'}})
    }})
  },
  filters:{
    imgsrc(v,that){
      return that._src + v
    }
  }
}
</script>
<style  lang="scss" scoped>
.waitremark {
  display: flex;justify-content: center;
  .item {
    width: 228px;background: #fff;margin-right: 15px;border-radius: 10px;
    .img {
      width: 228px;height: 228px;display: flex;justify-content: center;align-items: center;
      img {
        display: block;width: 150px;height: 150px;
      }
    }
    .info {
      .price {
        padding:0 10px;color:#d30a2b;font-size: 16px;font-weight: bold;
        span {
          font-size: 14px;margin-right: 5px;
        }
      }
      .h {
        padding:0 10px;font-size: 14px;color:#40403f;height: 38px;overflow: hidden;margin-top:5px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
      }
    }
    .remark {
      display: block;margin:0 auto;border:1px solid #ee5064;background:none;width: 94px;height: 28px;border-radius: 5px;color:#101010;margin-top:30px;margin-bottom:20px;font-size: 14px;
      &:hover {
        opacity: 0.8;
      }
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}
</style>
